import { createContext, useEffect, useState } from "react";
import { getCookie, setCookie } from "../functions/cookies";
export const ThemeContext = createContext();

const getInitialTheme = () => {
  if (typeof window !== "undefined") {
    // Check if a theme is saved in cookies
    const savedTheme = getCookie("theme");

    if (savedTheme) {
      return savedTheme; // Use theme from cookies if available
    }

    // Default to device preference if no theme is saved
    const prefersTheme = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    return prefersTheme ? "dark" : "light";
  }

  return "light"; // Fallback for SSR
};

export default function ThemeContextProvider({ children }) {
  const [theme, setTheme] = useState(getInitialTheme);

  const toggle = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  useEffect(() => {
    setCookie("theme", theme);
  }, [theme]);

  return (
    <div className={theme}>
      <ThemeContext.Provider value={{ theme, toggle }}>
        {children}
      </ThemeContext.Provider>
    </div>
  );
}
