
export const currentYear = new Date().getFullYear();

export const ProjectName = "guivus";
export const ProjectNameCap = "Guivus";
export const hostname = window.location.hostname;
export const subdomain =
  hostname.split(".").length > 0 ? hostname.split(".")[0] : null;
export const getDomain = () => {
  return window.location.hostname.includes("localhost")
    ? "localhost:3000"
    : "guivus.com";
};

export const domain = getDomain();

export const cookDomain = window.location.hostname.includes("localhost")
  ? `.${window.location.hostname}`
  : ".guivus.com";
export const protocol = domain.includes("localhost") ? "http" : "https";
export const getSubDomain = (subdomain) => {
  return `${protocol}://${subdomain}.${domain}`;
};

export const homeDomain = `${protocol}://${domain}`;
export const authDomain = getSubDomain("auth");
export const payDomain = getSubDomain("pay");
export const crmDomain = getSubDomain("crm");
export const chatDomain = getSubDomain("chat");
export const voiceDomain = getSubDomain("voice");
export const blogDomain = getSubDomain("blog");

