import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../export/env";


// Function to hash the UID
export function hash(uid) {
  const hashedValue = CryptoJS.AES.encrypt(uid, SECRET_KEY).toString();
  return hashedValue;
}

// Function to unhash the UID
export function unhash(hashedValue) {
  const bytes = CryptoJS.AES.decrypt(hashedValue, SECRET_KEY);
  const originalUID = bytes.toString(CryptoJS.enc.Utf8);
  return originalUID;
}