import React, { createContext, useEffect, useState } from "react";
import { auth, db } from "../utils/init-firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import {
  doc,
  runTransaction,
  serverTimestamp,
  getDoc,
  onSnapshot,
} from "firebase/firestore";
import { Toast } from "../export/toast";
import { deleteCookie, getCookie, setCookie } from "../functions/cookies";
import { hash, unhash } from "../functions/hash";
import { isLocal } from "../export/is";

export const GeneralAuthContext = createContext();

export default function AuthContextProvider({ children }) {
  const [showOverBoard, setShowOverBoard] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  const storedToken = getCookie("token");
  const signToken = getCookie("sign");
  const testToken = 'U2FsdGVkX19ImLoLWloD0FlTKEBz2I9LrDfUP1NK0/HS25NDttv/ULnmNySdbVT1'


  useEffect(() => {
    if (isLocal) {
      setCookie("token", testToken);
    }
  }, []);

  async function register({
    email,
    password,
    fullname,
    username,
    create = true,
    userId,
    emailVerified,
  }) {
    try {
      await runTransaction(db, async (transaction) => {
        let result;
        if (create) {
          result = await createUserWithEmailAndPassword(auth, email, password);
        }

        const uid = create ? result.user.uid : userId;

        const userData = {
          userId: uid,
          userEmail: email,
          userName: username,
          userFullname: fullname,
          emailVerified: emailVerified || false,
          isMember: true,
          isLogin: false,
          isAuth: false,
          isOnline: serverTimestamp(),
          isSubcribed: false,
          isSubcribedStatus: "",
          isAdmin: false,
          isVerified: false,
          isVerifiedStatus: "",
          joinDate: serverTimestamp(),
        };

        const userInfo = {
          userId: uid,
          userEmail: email,
          userFullname: fullname,
          userName: username,
        };

        const documentRefs = [
          { ref: doc(db, "users", uid), data: userData },
          { ref: doc(db, "usernames", username), data: userInfo },
          { ref: doc(db, "useremails", email), data: userInfo },

          { ref: doc(db, "usernotificationlists", uid), data: {} },
          { ref: doc(db, "userchatlists", uid), data: {} },
        ];

        for (const { ref, data } of documentRefs) {
          transaction.set(ref, data);
        }
      });
    } catch (error) {
      console.error("Registration failed:", error);

      Toast.fire({
        title: "Registration failed",
        text: "An error occurred. Please try again.",
        icon: "error",
      });

      setLoading(false);
      throw error;
    }
  }

  async function addUser(user) {
    try {
      // Handle user registration logic as you have it
      const baseUsername = user.email.replace("@gmail.com", "");
      let username = baseUsername;
      let isAvailable = false;

      // Function to check if a username is available
      const checkUsernameAvailability = async (username) => {
        const usernameDocRef = doc(db, "usernames", username);
        const usernameSnapshot = await getDoc(usernameDocRef);
        return !usernameSnapshot.exists();
      };

      // Keep checking for available username
      let attempts = 0;
      while (!isAvailable && attempts < 100) {
        isAvailable = await checkUsernameAvailability(username);
        if (!isAvailable) {
          username = `${baseUsername}${Math.floor(Math.random() * 90 + 10)}`;
          attempts++;
        }
      }

      if (isAvailable) {
        const whoa = localStorage.getItem("mywho") || "client";
        // Proceed to register the user with the available username
        await register({
          email: user.email,
          fullname: user.displayName,
          username: username,
          userId: user.uid,
          who: whoa,
          create: false,
          emailVerified: user.emailVerified,
        });
        localStorage.removeItem("mywho");
      } else {
        console.error(
          "Unable to find available username after multiple attempts."
        );
      }
    } catch (error) {
      console.error("Logout failed:", error);
      throw error;
    }
  }

  async function login({email, password}) {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );

    return userCredential;
  }

  async function SignWithGoogle() {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).then((result) => {
      GoogleAuthProvider.credentialFromResult(result);
    });
  }

  async function SignWithMicroSoft() {
     
  }

  async function logout() {
    try {
      await setCookie("sign", 'true');
      await deleteCookie("token");
      setCurrentUser(null);
      setUserData(null);   
    } catch (error) {
      console.error("Logout failed:", error);
      throw error;
    }
  }

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user && !signToken ) {
        const hashedToken = hash(user.uid);
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);
        if (!userDocSnapshot.exists()) {
          await addUser(user);
        }
        await setCookie("token", hashedToken);
        setCurrentUser(user.uid);
      } else if (!storedToken) {
        setLoading(false)
      }

      if (signToken){
        await signOut(auth);
        await deleteCookie("sign");
      }
    });

    return () => {
      unsubscribeAuth();
    };

    /* eslint-disable-next-line */
  }, [auth]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (storedToken) {
        try {
          const uid = unhash(storedToken);
          if (uid && !userData) {
            const userQuery = doc(db, "users", uid);
            const userDocSnapshot = await getDoc(userQuery);
            if (userDocSnapshot.exists()) {
              const userData = userDocSnapshot.data();
              setUserData(userData);
              setCurrentUser(uid);
              setLoading(false);
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setLoading(false);
        }
      }
    };
    const unsubscribe =
      currentUser &&
      onSnapshot(doc(db, "users", currentUser), (doc) => {
        const userData = doc.data();
        setUserData(userData);
      });
    fetchUserData();
    return () => {
      unsubscribe && unsubscribe();
    };
    /* eslint-disable-next-line */
  }, [storedToken]);

  const value = {
    currentUser,
    setCurrentUser,
    loading,
    setLoading,
    userData,
    setUserData,
    showOverBoard,
    setShowOverBoard,
    register,
    login,
    SignWithGoogle,
    SignWithMicroSoft,
    logout,
  };

  return (
    <GeneralAuthContext.Provider value={value}>
      {children}
    </GeneralAuthContext.Provider>
  );
}
