import React, { createContext, useContext, useEffect, useState } from "react";
import useAuth from "../providers/AuthProvider";
import useData from "../providers/DataProvider";
import { ThemeContext } from "./ThemeContext";
import { useParams, Link } from "react-router-dom";
import useRequest from "../hooks/useRequest";
import useCrud from "../hooks/useCrud";
import useHover from "../hooks/useHover";
import useRead  from "../hooks/useRead";



export const GeneralBrainContext = createContext();

export default function BrainContextProvider({ children }) {
  //contexts
  const { userNickname, userName, userEmail, ipAddress } = useData();
  const { currentUser, userData, logout } = useAuth();
  const { theme } = useContext(ThemeContext);
  //states
  const [isMobile, setIsMobile] = useState(false);
  const [dropdowns, setDropdowns] = useState(Array(40).fill(false));
  const [showOverlay, setshowOverlay] = useState({ show: false });
  const [chatList, setchatList] = useState([]);

  const toggleDropdown = (index) => {
    const updatedDropdowns = [...dropdowns];
    updatedDropdowns[index] = !updatedDropdowns[index];

    for (let i = 0; i < updatedDropdowns.length; i++) {
      if (i !== index) {
        updatedDropdowns[i] = false;
      }
    }

    setDropdowns(updatedDropdowns);
  };

 

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);

    const handleResize = () => {
      setIsMobile(mediaQuery.matches);
    };

    mediaQuery.addEventListener("change", handleResize);

    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  const { query } = useParams();
  const {
    getRequest,
    postRequest,
    putRequest,
    deleteRequest,
    error: useRequestError,
    loading: useRequestLoading,
  } = useRequest();
  const {
    createDoc,
    readDoc,
    updateDoc,
    deleteDoc,
    error: useCrudError,
    loading: useCrudLoading,
  } = useCrud();
  const contextValue = {
    // ----react
    useParams,
    Link,

    // ------hooks
    useRequest,
    useCrud,
    useHover,
    useRead,

    // -------states
    isMobile,
    toggleDropdown,
    dropdowns,
    setDropdowns,
    showOverlay,
    setshowOverlay,
    chatList,
    setchatList,

    // ---values
    query,
    getRequest,
    postRequest,
    putRequest,
    deleteRequest,
    useRequestError,
    useRequestLoading,
    createDoc,
    readDoc,
    updateDoc,
    deleteDoc,
    useCrudError,
    useCrudLoading,

    // ---------- Auth
    currentUser,
    userData,
    logout,
    theme,
    // ---------- Data
    userNickname,
    userName,
    userEmail,
    ipAddress,
  };

  return (
    <GeneralBrainContext.Provider value={contextValue}>
      {children}
    </GeneralBrainContext.Provider>
  );
}
