import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

export const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  
  export const Modal = Swal.mixin({
    toast: false,
    position: "center",
    showConfirmButton: true,
    confirmButtonText: "Close",
    didOpen: (modal) => {},
  });
  
  export const showConfirmDialog = async ({ title, text, icon } = {}) => {
    const { isConfirmed } = await Swal.fire({
      title: title || "Are you sure?",
      text: text || "This action cannot be undone!",
      icon: icon || "warning",
      showCancelButton: true,
      confirmButtonColor: "black",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, confirm!",
    });
    return isConfirmed; 
  };