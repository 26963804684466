import { useState, useEffect } from "react";
import { getDoc, doc, onSnapshot } from "firebase/firestore";
import { db } from "../utils/init-firebase"; 

 const useRead = ({ colId, docId, username, snap }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notFound, setNotFound] = useState(false); 

  useEffect(() => { 
    const fetchData = async () => {
      setLoading(true);
      try {
        let idToFetch = docId;

        // If no docId but username is provided, fetch userId from the usernames collection
        if (!docId && username) {
          const usernameRef = doc(db, "usernames", username);
          const usernameSnapshot = await getDoc(usernameRef);

          if (usernameSnapshot.exists()) {
            idToFetch = usernameSnapshot.data().userId;
          } else {
            setError("Username not found");
            setLoading(false);
            return;
          }
        }

        // If docId or userId exists, fetch data from the main collection
        if (idToFetch) {
          const docRef = doc(db, colId, idToFetch);

          // If snap is true, use onSnapshot for real-time updates
          if (snap) {
            const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
              if (docSnapshot.exists()) {
                setData(docSnapshot.data());
              } else {
                setNotFound(true);
                setError("Document not found");
              }
            }, (error) => {
              setError("Failed to fetch data in real-time");
            });

            // Cleanup listener when the component unmounts or dependencies change
            return () => unsubscribe();
          } else {
            const docSnapshot = await getDoc(docRef);

            if (docSnapshot.exists()) {
              setData(docSnapshot.data());
            } else {
              setNotFound(true);
              setError("Document not found");
            }
          }
        } else {
          setError("No document ID or username provided");
        }
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [colId, docId, username, snap]);

  return { data, loading, setData, error, notFound };
};

export default useRead;