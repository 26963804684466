import { useState } from "react";
import axios from "axios";
import env from "../export/env";

const useRequest = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (method, endpoint, data = {}, params = {}) => {
    setLoading(true);
    setError(null);

    const url = `${env.SERVER_URL}${endpoint}`;

    try {
      const response = await axios({
        method,
        url: url,
        data,
        params,
      });
      setLoading(false);
      setError(response?.error);
      return response.data;
    } catch (err) {
      setLoading(false);
      setError(error);
      throw err;
    }
  };

  const getRequest = async (endpoint, params = {}) =>
    await request("get", endpoint, null, params);

  const postRequest = async (endpoint, data = {}) =>
    await request("post", endpoint, data);

  const putRequest = async (endpoint, data = {}) =>
    await request("put", endpoint, data);

  const deleteRequest = async (endpoint, data = {}) =>
    await request("delete", endpoint, data);

  return {
    getRequest,
    postRequest,
    putRequest,
    deleteRequest,
    loading,
    error,
  };
};

export default useRequest;
