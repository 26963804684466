export const translations = {
    en: {
      welcome: "Welcome to our app!",
      greeting: "Hello, {{name}}!",
    },
    pt: {
      welcome: "Bem-vindo ao nosso aplicativo!",
      greeting: "Olá, {{name}}!",
    },
    es: {
      welcome: "¡Bienvenido a nuestra aplicación!",
      greeting: "Hola, {{name}}!",
    },
    fr: {
      welcome: "Bienvenue dans notre application!",
      greeting: "Bonjour, {{name}}!",
    },
    sp: {
      welcome: "Bienvenue dans notre application!",
      greeting: "Bonjour, {{name}}!",
    },
  };
  