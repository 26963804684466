import { useContext } from "react";
import { GeneralBrainContext } from "../contexts/BrainContext";

const useBrain = () => {
  const context = useContext(GeneralBrainContext);
  if (!context) {
    throw new Error(
      "use must be used within a GeneralBrainProvider"
    );
  }
  return context;
};

export default useBrain;
