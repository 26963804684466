import { createContext, useState, useEffect } from "react";
import { translations } from "../contents/Translations";
import { getCookie, setCookie } from "../functions/cookies";

export const TranslateContext = createContext();

// Function to get the browser's default language
const getBrowserLanguage = () => {
  if (typeof window !== "undefined") {
    const browserLang = navigator.language || navigator.userLanguage; // e.g., "en", "pt", "es", "fr"
    return browserLang.split("-")[0]; // Get the base language code (e.g., "en-US" -> "en")
  }
  return "en"; // Default fallback
};

// Function to get the initial language
const getInitialLanguage = () => {
  const supportedLanguages = ["en", "pt", "es", "fr"]; // Add your supported languages
  const savedLanguage = getCookie("language"); // Check if a language is already saved
  if (savedLanguage) {
    return savedLanguage;
  }

  // Otherwise, use the browser's default language
  const browserLang = getBrowserLanguage();
  return supportedLanguages.includes(browserLang) ? browserLang : "en"; // Fallback to English
};

export default function TranslateContextProvider({ children }) {
  // Set the initial language from localStorage or browser default
  const [language, setLanguage] = useState(getInitialLanguage);

  // Update localStorage whenever the language changes
  useEffect(() => {
    setCookie("language", language);
  }, [language]);

  // Translation function
  const translate = (key, params = {}) => {
    const keys = translations[language];
    let translation = keys?.[key] || key;

    // Replace placeholders (e.g., {{name}})
    Object.keys(params).forEach((param) => {
      translation = translation.replace(`{{${param}}}`, params[param]);
    });

    return translation;
  };

  // Change the language
  const changeLanguage = (lng) => {
    setLanguage(lng);
  };

  return (
    <TranslateContext.Provider value={{ language, changeLanguage, translate }}>
      {children}
    </TranslateContext.Provider>
  );
}
