import { useContext } from "react";
import { GeneralDataContext } from "../contexts/DataContext";

const useData = () => {
  const context = useContext(GeneralDataContext);
  if (!context) {
    throw new Error(
      "useDataProvider must be used within a GeneralDataProvider"
    );
  }
  return context;
};

export default useData;
