import useRequest from "./useRequest";

const useCrud = () => {
  const { postRequest, error, loading } = useRequest();

  const createDoc = async ({ token, colId, docId, data }) =>
    await postRequest("/crud/create", { token, colId, docId, data });

  const readDoc = async ({ token, colId, docId }) =>
    await postRequest("/crud/read", { token, colId, docId });

  const updateDoc = async ({ token, colId, docId, data }) =>
    await postRequest("/crud/update", { token, colId, docId, data });

  const deleteDoc = async ({ token, colId, docId }) =>
    await postRequest("/crud/delete", { token, colId, docId });

  return {
    createDoc,
    readDoc,
    updateDoc,
    deleteDoc,
    loading,
    error,
  };
};

export default useCrud;
