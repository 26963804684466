import { cookDomain } from "../data/info";

// Helper function to get cookies
export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
};

// Helper function to set cookies
export const setCookie = (name, value) => {
  document.cookie = `${name}=${value}; path=/; domain=${cookDomain}`;
};

// Helper function to delete a cookie
export const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${cookDomain}`;
};
