import { isLocal } from "./is";

const SERVER_URL = isLocal
  ? process.env.REACT_APP_SERVER_URL
  : process.env.REACT_APP_SERVER_URL_PROD;

export const SECRET_KEY = process.env.REACT_APP_JWT_SECRET;
// Export the data
const env = {
  SERVER_URL,
  SECRET_KEY,
};
export default env;
