import { subdomain } from "../data/info";

export const isCrm = subdomain === "crm";

export const isLocal = window.location.hostname.includes("localhost");

 


 
