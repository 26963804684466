import React from "react";
import useBrain from "../../providers/BrainProvider";

const PageIsLoading = () => {
  const { theme } = useBrain();

  return (
    <div
      className={`h-dvh flex justify-center items-center ${
        theme === "dark" ? "bg" : ""
      }`}
    >
      <div className="wrapperleg">
        <div className={theme === 'dark' ? "circleleg bg-white" : 'circleleg bg-black'}></div>
        <div className={theme === 'dark' ? "circleleg bg-white" : 'circleleg bg-black'}></div>
        <div className={theme === 'dark' ? "circleleg bg-white" : 'circleleg bg-black'}></div>
        <div className={theme === 'dark' ? "shadowleg bg-stone-400" : 'shadowleg bg-slate-800'}></div>
        <div className={theme === 'dark' ? "shadowleg bg-stone-400" : 'shadowleg bg-slate-800'}></div>
        <div className={theme === 'dark' ? "shadowleg bg-stone-400" : 'shadowleg bg-slate-800'}></div>
      </div>
    </div>
  );
};

export default PageIsLoading;
