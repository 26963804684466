import React, { createContext, useState } from "react";

export const GeneralDataContext = createContext();

export default function DataContextProvider({ children }) {
  const [loading, setLoading] = useState(true);
 
  const value = {
    loading,
    setLoading,
  };

  return (
    <GeneralDataContext.Provider value={value}>
      {children}
    </GeneralDataContext.Provider>
  );
}
